<script setup>
import { useAuthStore } from "~/store/auth.js";
import { storeToRefs } from "pinia";
import { logRouteDetails } from "~/utils/routeLogger";

const route = useRoute();
const activeTab = ref("my-details");

const tabMapping = {
  "/dashboard/settings/my-details": "my-details",
  "/dashboard/settings/connected-apps": "connected-apps",
  "/dashboard/settings/password": "password",
  "/dashboard/settings/notification": "notification",
  "/dashboard/settings/domain": "domain",
  "/dashboard/settings/api": "api",
};

watchEffect(() => {
  activeTab.value = tabMapping[route.path] || "my-details";
});

const activeSubMenuTab = ref("");
const panelMenuExpandedKeys = ref({});
const panelMenuItems = ref([
  {
    key: "my-details",
    label: "My details",
  },
  {
    key: "connected-apps",
    label: "Connected apps",
  },
  {
    key: "notification",
    label: "Notification",
  },
  {
    key: "domain",
    label: "Domain",
  },
  {
    key: "api",
    label: "API",
  },
]);

const useAuth = useAuthStore();

const { userInfo } = storeToRefs(useAuth);

if (userInfo.value?.show_changePassword) {
  panelMenuItems.value.splice(1, 0, {
    key: "password",
    label: "Password",
  });
}
</script>
<template>
  <div class="h-full">
    <PanelMenu
      id="settings-leftpanel"
      :expandedKeys="panelMenuExpandedKeys"
      :model="panelMenuItems"
      class="mt-0 overflow-y-auto w-full h-full my-4 bg-white dark:bg-[#1f1f1f]"
    >
      <template #item="{ item }">
        <NuxtLink
          class="flex items-center !border-none h-12 bg-white dark:bg-[#1f1f1f] !p-[1.7rem] !mt-3 cursor-pointer !w-full px-10 !rounded-lg !justify-between !font-medium !pl-6"
          :class="{
            'tabActive !bg-[#f9f9f9] dark:!bg-[#181818]':
              activeTab == item.key || activeSubMenuTab == item.key,
          }"
          type="button"
          :to="`/dashboard/settings/${item.key}`"
        >
          <span
            :class="{
              '!text-black dark:!text-gray-300 font-bold': activeTab == item.key,
              'font-bold text-white': activeSubMenuTab == item.key,
              'bg-red-500': activeTab == item.key && activeSubMenuTab == item.key,
            }"
            class="select-none text-gray-500"
            >{{ item.label }}</span
          >
        </NuxtLink>
      </template>
    </PanelMenu>
  </div>
</template>

<style lang="postcss">
#settings-leftpanel
  > .p-panelmenu-panel
  > .p-panelmenu-header
  .p-panelmenu-header-content {
  @apply !border-none !shadow-none !rounded-xl;
}
</style>
